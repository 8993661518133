import { createContext, useState } from 'react';

import Home from './container/Home';

interface ActiveButtonContext {
  activeDoor: boolean;
  setActiveDoor: (active: boolean) => void;
  activeIdDoor: string;
  setActiveIdDoor: (active: string) => void;
  activeSeat: boolean;
  setActiveSeat: (active: boolean) => void;
  activeIdSeat: string;
  setActiveIdSeat: (active: string) => void;
  activeTour: boolean;
  setActiveTour: (active: boolean) => void;
  activeDrive: boolean;
  setActiveDrive: (active: boolean) => void;
  isHideDuringDrive: boolean;
  setIsHideDuringDrive: (active: boolean) => void;
  isFootnote: boolean;
  setIsFootnote: (active: boolean) => void;
  activeIdFootnote: number;
  setActiveIdFootnote: (active: number) => void;
}

export const ActiveButtonContext = createContext<ActiveButtonContext>(null!);

function App() {
  const [activeDoor, setActiveDoor] = useState(false);
  const [activeIdDoor, setActiveIdDoor] = useState('door_2');
  const [activeSeat, setActiveSeat] = useState(false);
  const [activeIdSeat, setActiveIdSeat] = useState('salon_2');
  const [activeTour, setActiveTour] = useState(false);
  const [activeDrive, setActiveDrive] = useState(false);
  const [isHideDuringDrive, setIsHideDuringDrive] = useState(false);
  const [isFootnote, setIsFootnote] = useState(false);
  const [activeIdFootnote, setActiveIdFootnote] = useState(1);

  return (
    <ActiveButtonContext.Provider
      value={{
        activeDoor,
        setActiveDoor,
        activeIdDoor,
        setActiveIdDoor,
        activeSeat,
        setActiveSeat,
        activeIdSeat,
        setActiveIdSeat,
        activeTour,
        setActiveTour,
        activeDrive,
        setActiveDrive,
        isHideDuringDrive,
        setIsHideDuringDrive,
        isFootnote,
        setIsFootnote,
        activeIdFootnote,
        setActiveIdFootnote,
      }}
    >
      <Home />
    </ActiveButtonContext.Provider>
  );
}

export default App;
