import clsx from 'clsx';

import useMediaQuery from '../../../../useMediaQuery';

import styles from './Icon.module.scss';

interface SecondDiscProps {
  activeDisc: String;
  setActiveDisc: (active: string) => void;
}

const SecondDisc = ({ activeDisc, setActiveDisc }: SecondDiscProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <svg
      width={isMobile ? '20' : '24'}
      height={isMobile ? '20' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.root, { [styles.active]: activeDisc === 'wheels_2' })}
      id="wheels_2"
      onClick={() => setActiveDisc('wheels_2')}
    >
      <g clipPath="url(#clip0_234_99)">
        <path d="M20.4797 3.52031C18.2297 1.27031 15.1781 0.0046875 12 0C9.62812 0 7.30781 0.703125 5.33437 2.02031C3.36093 3.34219 1.82343 5.21719 0.914058 7.40625C0.00468329 9.6 -0.234379 12.0141 0.229683 14.3391C0.693746 16.6687 1.8375 18.8062 3.51562 20.4844C5.19375 22.1625 7.33125 23.3062 9.66093 23.7703C11.9906 24.2344 14.4 23.9953 16.5937 23.0859C18.7875 22.1766 20.6625 20.6391 21.9797 18.6656C23.2969 16.6922 24 14.3719 24 12C23.9953 8.81719 22.7297 5.77031 20.4797 3.52031ZM12 21.4734C6.76875 21.4734 2.52656 17.2313 2.52656 12C2.52656 6.76875 6.76875 2.52656 12 2.52656C17.2312 2.52656 21.4734 6.76875 21.4734 12C21.4734 17.2313 17.2312 21.4734 12 21.4734Z" />
        <path d="M12 12.0141C12.0026 12.0141 12.0047 12.012 12.0047 12.0094C12.0047 12.0068 12.0026 12.0047 12 12.0047C11.9975 12.0047 11.9954 12.0068 11.9954 12.0094C11.9954 12.012 11.9975 12.0141 12 12.0141Z" />
        <path d="M14.4281 10.7297L11.8594 9.24846L16.1016 3.26721L17.4844 4.06409L14.4281 10.7297Z" />
        <path d="M11.9953 12.0282C11.9979 12.0282 12 12.0261 12 12.0235C12 12.0209 11.9979 12.0188 11.9953 12.0188C11.9927 12.0188 11.9906 12.0209 11.9906 12.0235C11.9906 12.0261 11.9927 12.0282 11.9953 12.0282Z" />
        <path d="M14.7281 12.2672L13.3828 9.62815L20.1984 6.92346L20.9203 8.34377L14.7281 12.2672Z" />
        <path d="M11.9813 12.0329C11.9838 12.0329 11.9859 12.0308 11.9859 12.0282C11.9859 12.0256 11.9838 12.0235 11.9813 12.0235C11.9787 12.0235 11.9766 12.0256 11.9766 12.0282C11.9766 12.0308 11.9787 12.0329 11.9813 12.0329Z" />
        <path d="M14.1422 13.7204L14.4516 10.7766L21.6375 12.2157L21.4735 13.8047L14.1422 13.7204Z" />
        <path d="M11.9719 12.0282C11.9744 12.0282 11.9765 12.0261 11.9765 12.0235C11.9765 12.0209 11.9744 12.0188 11.9719 12.0188C11.9693 12.0188 11.9672 12.0209 11.9672 12.0235C11.9672 12.0261 11.9693 12.0282 11.9719 12.0282Z" />
        <path d="M12.8578 14.6203L14.7187 12.3188L19.964 17.4422L18.9609 18.6844L12.8578 14.6203Z" />
        <path d="M11.9626 12.0188C11.9652 12.0188 11.9673 12.0167 11.9673 12.0141C11.9673 12.0115 11.9652 12.0094 11.9626 12.0094C11.96 12.0094 11.9579 12.0115 11.9579 12.0141C11.9579 12.0167 11.96 12.0188 11.9626 12.0188Z" />
        <path d="M11.2922 14.6766L14.1094 13.7625L15.7125 20.9156L14.1985 21.4078L11.2922 14.6766Z" />
        <path d="M11.9578 12.0094C11.9604 12.0094 11.9625 12.0073 11.9625 12.0047C11.9625 12.0021 11.9604 12 11.9578 12C11.9552 12 11.9531 12.0021 11.9531 12.0047C11.9531 12.0073 11.9552 12.0094 11.9578 12.0094Z" />
        <path d="M9.94687 13.8703L12.8063 14.6344L10.2609 21.5109L8.71875 21.0984L9.94687 13.8703Z" />
        <path d="M11.9625 11.9953C11.965 11.9953 11.9671 11.9932 11.9671 11.9906C11.9671 11.9881 11.965 11.986 11.9625 11.986C11.9599 11.986 11.9578 11.9881 11.9578 11.9906C11.9578 11.9932 11.9599 11.9953 11.9625 11.9953Z" />
        <path d="M9.25781 12.4641L11.2406 14.6625L5.35781 19.0407L4.28906 17.8547L9.25781 12.4641Z" />
        <path d="M11.9719 11.9906C11.9744 11.9906 11.9765 11.9885 11.9765 11.9859C11.9765 11.9834 11.9744 11.9813 11.9719 11.9813C11.9693 11.9813 11.9672 11.9834 11.9672 11.9859C11.9672 11.9885 11.9693 11.9906 11.9719 11.9906Z" />
        <path d="M9.45 10.9078L9.91406 13.8328L2.59688 14.3016L2.34375 12.7219L9.45 10.9078Z" />
        <path d="M11.9859 11.9859C11.9885 11.9859 11.9906 11.9838 11.9906 11.9813C11.9906 11.9787 11.9885 11.9766 11.9859 11.9766C11.9833 11.9766 11.9812 11.9787 11.9812 11.9813C11.9812 11.9838 11.9833 11.9859 11.9859 11.9859Z" />
        <path d="M10.4578 9.70781L9.25312 12.4125L2.85938 8.81719L3.51094 7.35938L10.4578 9.70781Z" />
        <path d="M11.9954 11.9953C11.998 11.9953 12.0001 11.9932 12.0001 11.9906C12.0001 11.9881 11.998 11.986 11.9954 11.986C11.9928 11.986 11.9907 11.9881 11.9907 11.9906C11.9907 11.9932 11.9928 11.9953 11.9954 11.9953Z" />
        <path d="M11.9532 9.24845L9.4688 10.8609L6.06567 4.36407L7.4063 3.49689L11.9532 9.24845Z" />
        <path d="M12 12.0047C12.0026 12.0047 12.0047 12.0026 12.0047 12C12.0047 11.9975 12.0026 11.9954 12 11.9954C11.9975 11.9954 11.9954 11.9975 11.9954 12C11.9954 12.0026 11.9975 12.0047 12 12.0047Z" />
        <path d="M13.4625 9.67502H10.5L11.1797 2.37659H12.7781L13.4625 9.67502Z" />
        <path d="M12 15.225C13.7811 15.225 15.225 13.7811 15.225 12C15.225 10.2189 13.7811 8.77502 12 8.77502C10.2189 8.77502 8.77502 10.2189 8.77502 12C8.77502 13.7811 10.2189 15.225 12 15.225Z" />
      </g>
      <defs>
        <clipPath id="clip0_234_99">
          <rect
            width="24"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecondDisc;
