import clsx from 'clsx';

import ButtonWrapper from '../../../shared/ButtonWrapper';

import styles from './EngineOptions.module.scss';
import Supercharger from './Supercharger';
import BlowingSystem from './BlowingSystem';

interface EngineOptionsProps {
  activeChangeEngine: String;
}

const EngineOptions = ({ activeChangeEngine }: EngineOptionsProps) => {
  return (
    <ButtonWrapper
      className={clsx(styles.root, {
        [styles.flex]: activeChangeEngine === 'engine',
      })}
    >
      <Supercharger />
      <BlowingSystem />
    </ButtonWrapper>
  );
};

export default EngineOptions;
