import clsx from 'clsx';
import { useContext } from 'react';

import { carFootnotes } from '../../data/carFootnotes';
import footnoteMobile from '../../assets/footnote_car_mobile.svg';
import footnote from '../../assets/footnote_car.svg';
import useMediaQuery from '../useMediaQuery';
import { ActiveButtonContext } from '../../App';

import styles from './FootnoteCar.module.scss';

const FootnoteCar = () => {
  const { isFootnote, activeIdFootnote } = useContext(ActiveButtonContext);
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div
      className={clsx(styles.footnote, {
        [styles.footnoteActive]: isFootnote,
      })}
    >
      {carFootnotes
        .filter((item) => item.id === activeIdFootnote)
        .map((item) => (
          <div
            key={item.id}
            className={styles.text}
          >
            {item.text}
          </div>
        ))}
      <img
        src={isMobile ? footnoteMobile : footnote}
        className={styles.icon}
      />
    </div>
  );
};

export default FootnoteCar;
