import { useState } from 'react';
import clsx from 'clsx';

import styles from './BlowingSystem.module.scss';

const BlowingSystem = () => {
  const [activeId, setActiveId] = useState('engine_04');
  const [isActive, setIsActive] = useState(false);
  const engineButton = ['engine_03', 'engine_04'];

  const handleChange = (id: string) => {
    setIsActive(!isActive);
    setActiveId(id);
  };

  return (
    <>
      {engineButton.map((item, index) => (
        <div
          key={index}
          style={{
            visibility: activeId !== item ? 'visible' : 'hidden',
          }}
          className={clsx(styles.root, { [styles.active]: isActive })}
          onClick={() => handleChange(item)}
          id={item}
        >
          blowing system
        </div>
      ))}
    </>
  );
};

export default BlowingSystem;
