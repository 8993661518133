import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import useMediaQuery from '../../../useMediaQuery';
import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface IOpenDoorsIconProps {
  isHide: boolean;
  isHideDuringDrive: boolean;
}

const OpenDoorsIcon = ({ isHide, isHideDuringDrive }: IOpenDoorsIconProps) => {
  const [isActive, setIsActive] = useState(false);
  const doorButton = ['door_1', 'door_2'];
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeIdDoor, setActiveIdDoor, activeDoor, setActiveDoor, activeSeat, activeTour } =
    useContext(ActiveButtonContext);

  useEffect(() => {
    if (activeSeat || activeTour) {
      setActiveIdDoor('door_2');
      setIsActive(false);
    }
  }, [activeSeat, activeTour]);

  const handleChange = (id: string) => {
    setActiveDoor(true);
    setTimeout(() => {
      setIsActive(!isActive);
      setActiveIdDoor(id);
      setActiveDoor(false);
    }, 1800);
  };

  return (
    <div
      className={clsx({
        [styles.none]: isHideDuringDrive || isHide,
      })}
    >
      {doorButton.map((item, index) => (
        <button
          key={index}
          id={item}
          onClick={() => handleChange(item)}
          disabled={activeDoor || activeSeat}
          className={clsx(styles.button, {
            [styles.disabledButton]: activeDoor || activeSeat,
            [styles.block]: activeIdDoor !== item,
            [styles.none]: activeIdDoor === item,
          })}
        >
          <svg
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(styles.icon, {
              [styles.activeIcon]: isActive,
              [styles.disabledIcon]: activeDoor || activeSeat,
            })}
          >
            <g clipPath="url(#clip0_407_1105)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0028 9.40875V13.5823L19.0028 16.4113V21.9993C19.0028 23.1027 18.1055 24 17.0021 24H6.99852C5.89511 24 4.99779 23.1027 4.99779 21.9993V16.4113V13.5823V9.40875L2.70398 11.7026C2.50891 11.8976 2.25278 11.9957 1.99671 11.9957C1.74058 11.9957 1.48451 11.8976 1.28944 11.7026C0.898313 11.3114 0.898313 10.6792 1.28944 10.2881L4.99779 6.57972V6.22958C4.99779 4.61298 5.37991 2.9924 6.10317 1.54489C6.21318 1.32581 6.39826 1.15475 6.62634 1.06374L6.7394 1.01773C10.1316 -0.338753 13.868 -0.339737 17.2602 1.01773L17.3742 1.06374C17.6023 1.15475 17.7874 1.32581 17.8974 1.54489C18.6207 2.9924 19.0028 4.61298 19.0028 6.22958V6.57972L22.7111 10.2881C23.1023 10.6792 23.1023 11.3114 22.7111 11.7026C22.5161 11.8976 22.2599 11.9957 22.0039 11.9957C21.7477 11.9957 21.4917 11.8976 21.2966 11.7026L19.0028 9.40875ZM16.0018 18.9981C16.0018 19.3193 15.8478 19.6214 15.5867 19.8094C15.4136 19.9345 15.2086 19.9985 15.0015 19.9985C14.8955 19.9985 14.7884 19.9815 14.6854 19.9475C12.9538 19.3703 11.0471 19.3703 9.31546 19.9475C9.01034 20.0465 8.67525 19.9975 8.41416 19.8094C8.15306 19.6213 7.99902 19.3193 7.99902 18.9981V15.9971C7.99902 14.8937 8.89635 13.9963 9.99976 13.9963H14.0012C15.1046 13.9963 16.0019 14.8937 16.0019 15.9971L16.0018 18.9981ZM10.27 4.51493L8.68342 5.04411C8.18721 5.21015 7.90211 5.72934 8.02919 6.23652L9.02963 10.2379C9.10367 10.5321 9.30671 10.7772 9.58183 10.9042C9.85793 11.0312 10.177 11.0252 10.4471 10.8902C11.4205 10.403 12.5809 10.403 13.5542 10.8902C13.6943 10.9602 13.8483 10.9952 14.0014 10.9952C14.1444 10.9952 14.2865 10.9652 14.4195 10.9042C14.6946 10.7772 14.8977 10.5321 14.9717 10.2379L15.9721 6.23652C16.0991 5.72934 15.814 5.21015 15.3178 5.04411L13.7312 4.51493C12.6139 4.14378 11.3854 4.14378 10.27 4.51493Z"
              />
            </g>
          </svg>
        </button>
      ))}
    </div>
  );
};

export default OpenDoorsIcon;
