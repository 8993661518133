import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import Button from '../../shared/Button';
import { buttonsBlock } from '../../../data/buttonsBlock';
import { colorsBodyColor } from '../../../data/colorsBodyColor';
import { discsColors } from '../../../data/discsColors';
import ButtonWrapper from '../../shared/ButtonWrapper';
import useMediaQuery from '../../useMediaQuery';
import { ActiveButtonContext } from '../../../App';

import styles from './ButtonsBlock.module.scss';
import ColorPalette from './ColorPalette';
import EngineOptions from './EngineOptions';
import DiscsOptions from './DiscsOptions';
import ColorPaletteSlider from './ColorPaletteSlider';

const ButtonsBlock = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeChange, setActiveChange] = useState('');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { activeDrive, isHideDuringDrive } = useContext(ActiveButtonContext);

  const handleChange = (name: string) => {
    setIsActive(true);
    setActiveChange(name);

    if (activeChange === name) {
      setActiveChange('');
    }
  };

  return (
    <>
      <div
        className={clsx(styles.root, {
          [styles.none]: activeDrive || isHideDuringDrive,
        })}
      >
        <ButtonWrapper className={styles.flex}>
          {buttonsBlock.map((button) => (
            <Button
              key={button.id}
              onClick={() => handleChange(button.name)}
              isActive={isActive}
              activeChange={activeChange}
            >
              {button.name}
            </Button>
          ))}
        </ButtonWrapper>
      </div>
      <div
        className={clsx(styles.selectedButton, {
          [styles.none]: activeDrive || isHideDuringDrive,
        })}
      >
        <ColorPalette
          name="body_color"
          activeChangeBodyColor={activeChange}
          data={colorsBodyColor}
          color="#B2B2B2"
        />
        <div
          className={clsx(styles.discsWrapper, {
            [styles.flex]: activeChange === 'disk',
          })}
        >
          <DiscsOptions activeChangeDisc={activeChange} />
          {isMobile ? (
            <ColorPaletteSlider
              activeChangeDisc={activeChange}
              name="disk_color"
              data={discsColors}
              color="#6D470D"
            />
          ) : (
            <ColorPalette
              activeChangeDisc={activeChange}
              name="disk_color"
              data={discsColors}
              color="#6D470D"
              disc
            />
          )}
        </div>
        <EngineOptions activeChangeEngine={activeChange} />
      </div>
    </>
  );
};

export default ButtonsBlock;
