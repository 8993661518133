import { useContext } from 'react';
import clsx from 'clsx';

import useMediaQuery from '../useMediaQuery';
import { ActiveButtonContext } from '../../App';

import styles from './MenuButtons.module.scss';
import ButtonsBlock from './ButtonsBlock';
import TourButton from './TourButton';
import IconsButtonsBlock from './IconsButtonsBlock';
import DriveIcon from './DriveIcon';
import TourButtonsBlock from './TourButtonsBlock';

const MenuButtons = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeTour } = useContext(ActiveButtonContext);

  return (
    <>
      <div className={styles.root}>
        <div
          className={clsx(styles.wrapper, {
            [styles.none]: activeTour,
          })}
        >
          <TourButton />
          <ButtonsBlock />
          {!isMobile && (
            <>
              <IconsButtonsBlock />
              <DriveIcon />
            </>
          )}
        </div>
        <TourButtonsBlock />
      </div>
    </>
  );
};

export default MenuButtons;
