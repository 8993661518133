import { useWindowSize } from '@react-hook/window-size';
import { useContext } from 'react';
import clsx from 'clsx';

import MenuButtons from '../../components/MenuButtons';
import Loader from '../../components/Loader';
import useMediaQuery from '../../components/useMediaQuery';
import IconsButtonsBlock from '../../components/MenuButtons/IconsButtonsBlock';
import DriveIcon from '../../components/MenuButtons/DriveIcon';
import { ActiveButtonContext } from '../../App';
import FootnoteCar from '../../components/FootnoteCar';

import styles from './Home.module.scss';

const Home = () => {
  const [width, height] = useWindowSize();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeTour } = useContext(ActiveButtonContext);

  return (
    <>
      <Loader />
      <div
        className={styles.root}
        style={{
          width: width,
          height: height,
        }}
      >
        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 0,
          }}
          src="verge_app.html"
        />
        {isMobile && (
          <div
            className={clsx(styles.wrapper, {
              [styles.none]: activeTour,
            })}
          >
            <IconsButtonsBlock />
            <DriveIcon />
          </div>
        )}
        <MenuButtons />
        <FootnoteCar />
      </div>
    </>
  );
};

export default Home;
