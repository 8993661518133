import { useEffect, useRef, useState } from 'react';

import car from '../../assets/loader_car.png';

import styles from './Loader.module.scss';

const Loader = () => {
  const [position, setPosition] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);
  const currentPosition = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIntervalId(setInterval(getProgressPosition, 100));
  }, []);

  useEffect(() => {
    if (position >= 100) {
      clearInterval(intervalId);
    }
  }, [position, intervalId]);

  function getProgressPosition() {
    setPosition(Number(currentPosition?.current?.innerHTML.slice(0, -1)));
  }

  return (
    <>
      <div
        id="preloader_screen"
        className="preloader-screen"
      >
        <div className={styles.head}>Loading...</div>
        <img
          style={{ left: `${position}%`, transform: `translate(-${position}%, 0)` }}
          src={car}
          className={styles.car}
        />
        <div
          id="loading_text"
          className="loading-text"
          ref={currentPosition}
        >
          0%
        </div>
      </div>
    </>
  );
};
export default Loader;
