import clsx from 'clsx';

import useMediaQuery from '../../../../useMediaQuery';

import styles from './ActiveColor.module.scss';

interface ActiveColorProps {
  fill: string;
}

const ActiveColor = ({ fill }: ActiveColorProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <svg
      width={isMobile ? '10' : '12'}
      height={isMobile ? '8' : '10'}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.root, { [styles.mobile]: isMobile })}
    >
      <path
        d="M1.33333 5.66667L3.99999 8.33333L10.6667 1.66667"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveColor;
