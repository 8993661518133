import { useContext } from 'react';

import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface NextIconProps {
  setIsActivePlay: (active: boolean) => void;
  setActiveId: (active: string) => void;
}

const NextIcon = ({ setIsActivePlay, setActiveId }: NextIconProps) => {
  const { setIsFootnote, activeIdFootnote, setActiveIdFootnote } = useContext(ActiveButtonContext);

  const handleChange = () => {
    setIsActivePlay(false);
    setActiveId('tour_pause');
    setIsFootnote(false);

    if (activeIdFootnote < 5) {
      setTimeout(() => {
        setIsFootnote(true);
      }, 100);
    } else {
      setIsFootnote(true);
    }

    if (activeIdFootnote < 5) {
      setActiveIdFootnote(activeIdFootnote + 1);
    }
  };

  return (
    <button
      id="tour_next"
      onClick={handleChange}
      className={styles.root}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <g clipPath="url(#clip0_649_1300)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.72796 0.616334C6.11945 0.226781 6.75261 0.228354 7.14217 0.619848L10.9825 4.47928C10.9825 4.4793 10.9824 4.47926 10.9825 4.47928C11.8194 5.32031 11.8198 6.67969 10.9825 7.52081C10.9825 7.5209 10.9826 7.52072 10.9825 7.52081L7.14219 11.3805C6.75264 11.772 6.11948 11.7736 5.72798 11.3841C5.33647 10.9945 5.33488 10.3614 5.72442 9.96987L9.56479 6.11016C9.62512 6.04956 9.62562 5.95114 9.56487 5.89011L5.72444 2.03054C5.33489 1.63905 5.33646 1.00589 5.72796 0.616334Z"
          />
          <path d="M2.3535 11.6618C2.05229 11.9645 1.59787 12.0559 1.20322 11.8928C0.808308 11.7302 0.55069 11.3446 0.55069 10.9179V1.05505C0.55069 0.627771 0.808308 0.242779 1.20322 0.0799079C1.59813 -0.0832242 2.05229 0.00812982 2.3535 0.310903L7.01568 4.99632C7.56067 5.54392 7.56067 6.42875 7.01568 6.97609L2.3535 11.6618Z" />
        </g>
        <defs>
          <clipPath id="clip0_649_1300">
            <rect
              width="12"
              height="12"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default NextIcon;
