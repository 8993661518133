import clsx from 'clsx';
import { useContext } from 'react';

import Button from '../../shared/Button';
import { ActiveButtonContext } from '../../../App';

import styles from './TourButton.module.scss';

const TourButton = () => {
  const { activeDoor, activeSeat, setActiveTour, activeIdSeat, activeDrive, isHideDuringDrive } =
    useContext(ActiveButtonContext);

  return (
    <div
      className={clsx(styles.root, {
        [styles.none]: activeDrive || isHideDuringDrive,
      })}
    >
      <Button
        id="tour_1"
        onClick={() => setActiveTour(true)}
        disabled={activeDoor || activeSeat || activeIdSeat === 'salon_1'}
      >
        tour
      </Button>
    </div>
  );
};

export default TourButton;
