import clsx from 'clsx';

import styles from './Button.module.scss';

interface IButtonProps {
  isActive?: Boolean;
  activeChange?: String;
  id?: String;
  disabled?: boolean;
}

const Button = ({
  id,
  isActive,
  activeChange,
  children,
  disabled,
  ...otherProps
}: React.ButtonHTMLAttributes<HTMLButtonElement> & React.PropsWithChildren & IButtonProps) => {
  return (
    <button
      id={id}
      type="button"
      disabled={disabled}
      className={clsx(styles.root, {
        [styles.active]: isActive && activeChange === children,
        [styles.disabled]: disabled,
      })}
      {...otherProps}
    >
      <div className={clsx({ [styles.active]: isActive && activeChange === children })}>
        {children}
      </div>
    </button>
  );
};

export default Button;
