import { useContext } from 'react';

import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface CloseIconProps {
  setIsActivePlay: (active: boolean) => void;
}

const CloseIcon = ({ setIsActivePlay }: CloseIconProps) => {
  const { setActiveTour, setIsFootnote, setActiveIdFootnote } = useContext(ActiveButtonContext);

  const handleChange = () => {
    setActiveTour(false);
    setIsActivePlay(true);
    setIsFootnote(false);
    setActiveIdFootnote(1);
  };

  return (
    <button
      id="tour_stop"
      onClick={handleChange}
      className={styles.root}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.40095 0.401163C0.871381 -0.069268 1.6341 -0.069268 2.10453 0.401163L5.99999 4.29662L9.89546 0.401163C10.3659 -0.069268 11.1286 -0.069268 11.599 0.401163C12.0695 0.871595 12.0695 1.63432 11.599 2.10475L7.70358 6.00021L11.599 9.89567C12.0695 10.3661 12.0695 11.1288 11.599 11.5993C11.1286 12.0697 10.3659 12.0697 9.89546 11.5993L5.99999 7.70379L2.10453 11.5993C1.6341 12.0697 0.871381 12.0697 0.40095 11.5993C-0.0694817 11.1288 -0.0694817 10.3661 0.40095 9.89567L4.29641 6.00021L0.40095 2.10475C-0.0694817 1.63432 -0.0694817 0.871595 0.40095 0.401163Z"
        />
      </svg>
    </button>
  );
};

export default CloseIcon;
