import clsx from 'clsx';

import useMediaQuery from '../../../../useMediaQuery';

import styles from './Icon.module.scss';

interface ThirdDiscDiscProps {
  activeDisc: String;
  setActiveDisc: (active: string) => void;
}

const ThirdDisc = ({ activeDisc, setActiveDisc }: ThirdDiscDiscProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <svg
      width={isMobile ? '20' : '24'}
      height={isMobile ? '20' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.root, { [styles.active]: activeDisc === 'wheels_3' })}
      id="wheels_3"
      onClick={() => setActiveDisc('wheels_3')}
    >
      <g clipPath="url(#clip0_234_96)">
        <path d="M20.4797 3.52031C18.2297 1.27031 15.1781 0.0046875 12 0C9.62812 0 7.30781 0.703125 5.33437 2.02031C3.36093 3.34219 1.82343 5.21719 0.914058 7.40625C0.00468329 9.6 -0.234379 12.0141 0.229683 14.3391C0.693746 16.6687 1.8375 18.8062 3.51562 20.4844C5.19375 22.1625 7.33125 23.3062 9.66093 23.7703C11.9906 24.2344 14.4 23.9953 16.5937 23.0859C18.7875 22.1766 20.6625 20.6391 21.9797 18.6656C23.2969 16.6922 24 14.3719 24 12C23.9953 8.81719 22.7297 5.77031 20.4797 3.52031ZM12 21.4734C6.76875 21.4734 2.52656 17.2313 2.52656 12C2.52656 6.76875 6.76875 2.52656 12 2.52656C17.2312 2.52656 21.4734 6.76875 21.4734 12C21.4734 17.2313 17.2312 21.4734 12 21.4734Z" />
        <path d="M11.9906 12.0282C11.9932 12.0282 11.9953 12.0261 11.9953 12.0235C11.9953 12.0209 11.9932 12.0188 11.9906 12.0188C11.9881 12.0188 11.986 12.0209 11.986 12.0235C11.986 12.0261 11.9881 12.0282 11.9906 12.0282Z" />
        <path d="M14.3157 13.7157L12.8625 9.24849L20.7469 7.82349L21.5297 10.2329L14.3157 13.7157Z" />
        <path d="M11.9672 12.0235C11.9698 12.0235 11.9719 12.0214 11.9719 12.0188C11.9719 12.0162 11.9698 12.0141 11.9672 12.0141C11.9646 12.0141 11.9625 12.0162 11.9625 12.0188C11.9625 12.0214 11.9646 12.0235 11.9672 12.0235Z" />
        <path d="M11.0719 14.7563L14.8781 11.9907L18.6657 19.05L16.6125 20.5407L11.0719 14.7563Z" />
        <path d="M11.9625 12C11.965 12 11.9671 11.9979 11.9671 11.9953C11.9671 11.9928 11.965 11.9907 11.9625 11.9907C11.9599 11.9907 11.9578 11.9928 11.9578 11.9953C11.9578 11.9979 11.9599 12 11.9625 12Z" />
        <path d="M9.08438 11.9907L12.8859 14.7563L7.34531 20.5407L5.29688 19.05L9.08438 11.9907Z" />
        <path d="M11.9813 11.986C11.9838 11.986 11.9859 11.9839 11.9859 11.9813C11.9859 11.9787 11.9838 11.9766 11.9813 11.9766C11.9787 11.9766 11.9766 11.9787 11.9766 11.9813C11.9766 11.9839 11.9787 11.986 11.9813 11.986Z" />
        <path d="M11.0954 9.24849L9.64224 13.7157L2.43286 10.2329L3.21567 7.82349L11.0954 9.24849Z" />
        <path d="M12 12.0047C12.0026 12.0047 12.0047 12.0026 12.0047 12C12.0047 11.9975 12.0026 11.9954 12 11.9954C11.9975 11.9954 11.9954 11.9975 11.9954 12C11.9954 12.0026 11.9975 12.0047 12 12.0047Z" />
        <path d="M14.3297 10.3125H9.62817L10.711 2.37659H13.2469L14.3297 10.3125Z" />
        <path d="M12 15.225C13.7811 15.225 15.225 13.7811 15.225 12C15.225 10.2189 13.7811 8.77502 12 8.77502C10.2189 8.77502 8.77502 10.2189 8.77502 12C8.77502 13.7811 10.2189 15.225 12 15.225Z" />
      </g>
      <defs>
        <clipPath id="clip0_234_96">
          <rect
            width="24"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThirdDisc;
