import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import useMediaQuery from '../../../useMediaQuery';
import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface ISeatIconProps {
  isHide: boolean;
  isHideDuringDrive: boolean;
}

const SeatIcon = ({ isHide, isHideDuringDrive }: ISeatIconProps) => {
  const [isActive, setIsActive] = useState(false);
  const seatButton = ['salon_1', 'salon_2'];
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeDoor, activeSeat, setActiveSeat, activeIdSeat, setActiveIdSeat, activeDrive } =
    useContext(ActiveButtonContext);

  useEffect(() => {
    if (activeDrive) {
      setActiveIdSeat('salon_1');
      setIsActive(true);
    }
  }, [activeDrive]);

  const handleChange = (id: string) => {
    setActiveSeat(true);
    setTimeout(() => {
      setIsActive(!isActive);
      setActiveIdSeat(id);
      setActiveSeat(false);
    }, 3600);
  };

  return (
    <div
      className={clsx({
        [styles.none]: isHideDuringDrive || isHide,
      })}
    >
      {seatButton.map((item, index) => (
        <button
          key={index}
          id={item}
          onClick={() => handleChange(item)}
          disabled={activeDoor || activeSeat}
          className={clsx(styles.button, {
            [styles.disabledButton]: activeDoor || activeSeat,
            [styles.block]: activeIdSeat !== item,
            [styles.none]: activeIdSeat === item,
          })}
        >
          <svg
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(styles.icon, {
              [styles.activeIcon]: isActive,
              [styles.disabledIcon]: activeDoor || activeSeat,
            })}
          >
            <path d="M3.61704 0.869426C4.38729 0.737465 5.11867 1.25486 5.25063 2.02511L5.44935 2.91016C5.55333 3.37308 5.5042 3.84279 5.32901 4.25907C5.29681 4.25522 5.26502 4.2518 5.23376 4.2488C5.11188 4.23713 4.98831 4.23122 4.86661 4.23122C4.10761 4.23122 3.39324 4.4613 2.83521 4.8734L2.4585 2.48587C2.33708 1.72073 2.85349 1.00022 3.61704 0.869426Z" />
            <path d="M7.60787 7.28261C6.90343 6.29025 6.05968 5.74056 5.10009 5.64891C4.44516 5.58624 3.83265 5.78884 3.46073 6.19068C3.14964 6.5267 3.02495 6.97973 3.10961 7.46637L4.92557 17.5834L5.29562 20.1709L5.75704 22.5802C5.82046 22.9116 6.11031 23.1512 6.44764 23.1512H19.872C20.171 23.1512 20.4373 22.9621 20.5358 22.6798L21.4753 19.9899C21.4818 19.9712 21.4876 19.9523 21.4924 19.9332C21.7864 18.7901 21.1303 17.6068 19.9989 17.2391C18.3669 16.7088 16.3327 16.5128 14.1159 16.6723C12.3455 16.7997 10.5744 17.156 9.02531 17.6891C9.05391 17.6046 9.08171 17.5199 9.10786 17.4348C9.80953 15.1505 9.69337 12.338 8.78924 9.71833C8.44046 8.70779 8.05405 7.91105 7.60787 7.28261Z" />
          </svg>
        </button>
      ))}
    </div>
  );
};

export default SeatIcon;
