import { useContext, useState } from 'react';
import clsx from 'clsx';

import useMediaQuery from '../../../useMediaQuery';
import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface ILightIconProps {
  isHide: boolean;
  isHideDuringDrive: boolean;
}

const LightIcon = ({ isHide, isHideDuringDrive }: ILightIconProps) => {
  const [activeId, setActiveId] = useState('headlights_2');
  const [isActive, setIsActive] = useState(false);
  const headLightsButton = ['headlights_1', 'headlights_2'];
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeDoor, activeSeat } = useContext(ActiveButtonContext);

  const handleChange = (id: string) => {
    setIsActive(!isActive);
    setActiveId(id);
  };

  return (
    <div
      className={clsx({
        [styles.none]: isHideDuringDrive || isHide,
      })}
    >
      {headLightsButton.map((item, index) => (
        <button
          key={index}
          id={item}
          onClick={() => handleChange(item)}
          disabled={activeDoor || activeSeat}
          className={clsx(styles.button, {
            [styles.disabledButton]: activeDoor || activeSeat,
            [styles.block]: activeId !== item,
            [styles.none]: activeId === item,
          })}
        >
          <svg
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(styles.icon, {
              [styles.activeIcon]: isActive,
              [styles.disabledIcon]: activeDoor || activeSeat,
            })}
          >
            <path d="M9.44625 3.65991H9.2775C7.06559 3.65991 4.94428 4.53859 3.38023 6.10264C1.81618 7.66669 0.9375 9.78801 0.9375 11.9999C0.9375 14.2118 1.81618 16.3331 3.38023 17.8972C4.94428 19.4612 7.06559 20.3399 9.2775 20.3399C9.39384 20.3608 9.51367 20.3494 9.62404 20.3071C9.73441 20.2648 9.83113 20.1932 9.90375 20.0999C11.5608 17.7239 12.4493 14.8967 12.4493 11.9999C12.4493 9.10309 11.5608 6.27597 9.90375 3.89991C9.85222 3.82626 9.78378 3.76604 9.70418 3.72428C9.62458 3.68252 9.53614 3.66045 9.44625 3.65991Z" />
            <path d="M14.7788 6.83984H22.5001C22.6492 6.83984 22.7923 6.78058 22.8978 6.67509C23.0033 6.5696 23.0626 6.42653 23.0626 6.27734C23.0626 6.12816 23.0033 5.98509 22.8978 5.8796C22.7923 5.77411 22.6492 5.71484 22.5001 5.71484H14.7788C14.6296 5.71484 14.4865 5.77411 14.3811 5.8796C14.2756 5.98509 14.2163 6.12816 14.2163 6.27734C14.2163 6.42653 14.2756 6.5696 14.3811 6.67509C14.4865 6.78058 14.6296 6.83984 14.7788 6.83984Z" />
            <path d="M22.5001 9.54736H14.7788C14.6296 9.54736 14.4865 9.60663 14.3811 9.71212C14.2756 9.81761 14.2163 9.96068 14.2163 10.1099C14.2163 10.259 14.2756 10.4021 14.3811 10.5076C14.4865 10.6131 14.6296 10.6724 14.7788 10.6724H22.5001C22.6492 10.6724 22.7923 10.6131 22.8978 10.5076C23.0033 10.4021 23.0626 10.259 23.0626 10.1099C23.0626 9.96068 23.0033 9.81761 22.8978 9.71212C22.7923 9.60663 22.6492 9.54736 22.5001 9.54736Z" />
            <path d="M22.5001 13.3835H14.7788C14.6296 13.3835 14.4865 13.4428 14.3811 13.5483C14.2756 13.6538 14.2163 13.7969 14.2163 13.946C14.2163 14.0952 14.2756 14.2383 14.3811 14.3438C14.4865 14.4493 14.6296 14.5085 14.7788 14.5085H22.5001C22.6492 14.5085 22.7923 14.4493 22.8978 14.3438C23.0033 14.2383 23.0626 14.0952 23.0626 13.946C23.0626 13.7969 23.0033 13.6538 22.8978 13.5483C22.7923 13.4428 22.6492 13.3835 22.5001 13.3835Z" />
            <path d="M22.5001 17.2161H14.7788C14.6296 17.2161 14.4865 17.2753 14.3811 17.3808C14.2756 17.4863 14.2163 17.6294 14.2163 17.7786C14.2163 17.9277 14.2756 18.0708 14.3811 18.1763C14.4865 18.2818 14.6296 18.3411 14.7788 18.3411H22.5001C22.6492 18.3411 22.7923 18.2818 22.8978 18.1763C23.0033 18.0708 23.0626 17.9277 23.0626 17.7786C23.0626 17.6294 23.0033 17.4863 22.8978 17.3808C22.7923 17.2753 22.6492 17.2161 22.5001 17.2161Z" />
          </svg>
        </button>
      ))}
    </div>
  );
};

export default LightIcon;
