import { useContext, useState } from 'react';
import clsx from 'clsx';

import useMediaQuery from '../../useMediaQuery';
import { ActiveButtonContext } from '../../../App';

import styles from './DriveIcon.module.scss';

const DriveIcon = () => {
  const [activeId, setActiveId] = useState('travel_2');
  const [isActive, setIsActive] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const travelsButton = ['travel_1', 'travel_2'];
  const isMobile = useMediaQuery('(max-width: 767px)');
  const {
    activeDrive,
    setActiveDrive,
    activeDoor,
    activeSeat,
    activeIdDoor,
    setIsHideDuringDrive,
  } = useContext(ActiveButtonContext);

  const handleChange = (id: string) => {
    setActiveDrive(!activeDrive);
    setIsActive(!isActive);
    setActiveId(id);
    setIsShow(false);
    setIsHideDuringDrive(true);

    setTimeout(() => {
      setIsHideDuringDrive(false);
    }, 5000);

    setTimeout(() => {
      setIsShow(true);
    }, 8000);
  };

  return (
    <div
      className={clsx(styles.root, {
        [styles.none]: !isShow,
      })}
    >
      {travelsButton.map((item, index) => (
        <button
          key={index}
          id={item}
          onClick={() => handleChange(item)}
          disabled={activeDoor || activeSeat || activeIdDoor === 'door_1'}
          className={clsx(styles.button, {
            [styles.activeButton]: isActive,
            [styles.disabledButton]: activeDoor || activeSeat || activeIdDoor === 'door_1',
            [styles.block]: activeId !== item,
            [styles.none]: activeId === item,
          })}
        >
          <svg
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(styles.icon, {
              [styles.disabledIcon]: activeDoor || activeSeat || activeIdDoor === 'door_1',
            })}
          >
            <g clipPath="url(#clip0_434_1125)">
              <path
                d="M23.672 4.46166L24 4.25475V0H23.2969C9.65625 0 0 11.0156 0 23.2969V24H24V22.839L23.672 22.6321C20.5313 20.6507 18.6562 17.2544 18.6562 13.5469C18.6562 9.83934 20.5313 6.44302 23.672 4.46166ZM9.63258 21.3315C9.41644 20.3803 9.28688 19.4005 9.24745 18.4195L10.6526 18.3631C10.6886 19.2585 10.8067 20.1523 11.0039 21.0199L9.63258 21.3315ZM10.7873 15.6856L9.39511 15.4874C9.53316 14.5175 9.76186 13.5561 10.0749 12.6299L11.4072 13.08C11.1217 13.9246 10.9132 14.8012 10.7873 15.6856ZM12.495 10.6325L11.2679 9.94561C11.7452 9.09291 12.3047 8.27859 12.9307 7.52536L14.0122 8.42419C13.4409 9.11152 12.9305 9.85448 12.495 10.6325ZM15.9058 6.52748L15.0052 5.44744C15.7575 4.82016 16.571 4.2593 17.4231 3.78052L18.112 5.00644C17.3347 5.44331 16.5924 5.95505 15.9058 6.52748ZM20.1057 2.58309C21.0314 2.26856 21.9925 2.03831 22.9623 1.89867L23.1626 3.29058C22.2783 3.41784 21.402 3.6278 20.558 3.91458L20.1057 2.58309Z"
                fill="#1C1C1C"
              />
            </g>
          </svg>
        </button>
      ))}
    </div>
  );
};

export default DriveIcon;
