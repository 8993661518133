import { useContext } from 'react';

import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface PrevIconProps {
  setIsActivePlay: (active: boolean) => void;
  setActiveId: (active: string) => void;
}

const PrevIcon = ({ setIsActivePlay, setActiveId }: PrevIconProps) => {
  const { setIsFootnote, activeIdFootnote, setActiveIdFootnote } = useContext(ActiveButtonContext);

  const handleChange = () => {
    setIsActivePlay(false);
    setActiveId('tour_pause');
    setIsFootnote(false);

    if (activeIdFootnote > 1) {
      setTimeout(() => {
        setIsFootnote(true);
      }, 100);
    } else {
      setIsFootnote(true);
    }

    if (activeIdFootnote > 1) {
      setActiveIdFootnote(activeIdFootnote - 1);
    }
  };

  return (
    <button
      id="tour_back"
      onClick={handleChange}
      className={styles.root}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <g clipPath="url(#clip0_649_1290)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.43315 11.3568C6.04165 11.7464 5.40849 11.7448 5.01894 11.3533L1.17864 7.49387C1.17862 7.49384 1.17866 7.49389 1.17864 7.49387C0.341688 6.65283 0.341317 5.29346 1.17855 4.45233C1.17864 4.45225 1.17847 4.45242 1.17855 4.45233L5.01892 0.592621C5.40846 0.201117 6.04162 0.199526 6.43313 0.589067C6.82463 0.978609 6.82622 1.61177 6.43668 2.00328L2.59632 5.86299C2.53598 5.92358 2.53549 6.022 2.59623 6.08304L6.43666 9.9426C6.82621 10.3341 6.82464 10.9673 6.43315 11.3568Z"
          />
          <path d="M9.80756 0.31089C10.1088 0.00811672 10.5632 -0.0832367 10.9578 0.079896C11.3527 0.242506 11.6104 0.628018 11.6104 1.05477L11.6104 10.9176C11.6104 11.3449 11.3527 11.7299 10.9578 11.8927C10.5629 12.0559 10.1088 11.9645 9.80756 11.6618L5.14538 6.97634C4.60038 6.42874 4.60038 5.54391 5.14538 4.99657L9.80756 0.31089Z" />
        </g>
        <defs>
          <clipPath id="clip0_649_1290">
            <rect
              width="12"
              height="12"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default PrevIcon;
