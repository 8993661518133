import clsx from 'clsx';

import styles from './ButtonWrapper.module.scss';

interface ButtonWrapperProps {
  className?: String;
}

const ButtonWrapper = ({ children, className }: React.PropsWithChildren & ButtonWrapperProps) => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

export default ButtonWrapper;
