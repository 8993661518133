import { useState } from 'react';
import clsx from 'clsx';

import { Color } from '../../../../types/color';
import ButtonWrapper from '../../../shared/ButtonWrapper';
import useMediaQuery from '../../../useMediaQuery';

import styles from './ColorPalette.module.scss';
import ActiveColor from './ActiveColor';

interface ColorPanelProps {
  activeChangeBodyColor?: String;
  activeChangeDisc?: String;
  data: Color[];
  name: String;
  color: String;
  disc?: Boolean;
}

const ColorPalette = ({
  data,
  name,
  activeChangeBodyColor,
  activeChangeDisc,
  color,
  disc,
}: ColorPanelProps) => {
  const [activeChangeColor, setActiveChangeColor] = useState(color);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleChange = (color: string) => {
    setActiveChangeColor(color);
  };

  return (
    <ButtonWrapper
      className={clsx(styles.root, {
        [styles.flex]: activeChangeBodyColor === 'body color' || activeChangeDisc === 'disk',
        [styles.disc]: disc,
      })}
    >
      {data.map((item) => (
        <div
          className={styles.wrapper}
          key={item.id}
        >
          <svg
            width={isMobile ? '16' : '24'}
            height={isMobile ? '16' : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => handleChange(item.color)}
            className={clsx(styles.icon, {
              [styles.active]: activeChangeColor === item.color,
            })}
          >
            <rect
              id={`${name}_${item.id}`}
              width="24"
              height="24"
              rx="4"
              fill={item.color}
            />
          </svg>
          {activeChangeColor === item.color && (
            <ActiveColor fill={activeChangeColor === '#FAFAFA' ? '#000000' : '#FAFAFA'} />
          )}
        </div>
      ))}
    </ButtonWrapper>
  );
};

export default ColorPalette;
