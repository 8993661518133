import { useState } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper';

import { Color } from '../../../../types/color';
import ButtonWrapper from '../../../shared/ButtonWrapper';
import useMediaQuery from '../../../useMediaQuery';

import styles from './ColorPaletteSlider.module.scss';
import ActiveColor from './ActiveColor';

import 'swiper/css';
import 'swiper/css/pagination';

interface ColorPanelProps {
  activeChangeBodyColor?: String;
  activeChangeDisc?: String;
  data: Color[];
  name: String;
  color: String;
}

const ColorPaletteSlider = ({
  data,
  name,
  activeChangeBodyColor,
  activeChangeDisc,
  color,
}: ColorPanelProps) => {
  const [activeChangeColor, setActiveChangeColor] = useState(color);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleChange = (color: string) => {
    setActiveChangeColor(color);
  };

  return (
    <ButtonWrapper
      className={clsx(styles.root, {
        [styles.flex]: activeChangeBodyColor === 'body color' || activeChangeDisc === 'disk',
      })}
    >
      <Swiper
        slidesPerView={6}
        spaceBetween={30}
        freeMode={true}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {data.map((item) => (
          <SwiperSlide
            style={{ position: 'relative' }}
            key={item.id}
          >
            <svg
              width={isMobile ? '16' : '24'}
              height={isMobile ? '16' : '24'}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleChange(item.color)}
              className={clsx(styles.icon, {
                [styles.active]: activeChangeColor === item.color,
              })}
            >
              <rect
                id={`${name}_${item.id}`}
                width="24"
                height="24"
                rx="4"
                fill={item.color}
              />
            </svg>
            {activeChangeColor === item.color && (
              <ActiveColor fill={activeChangeColor === '#FAFAFA' ? '#000000' : '#FAFAFA'} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </ButtonWrapper>
  );
};

export default ColorPaletteSlider;
