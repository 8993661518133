import React from 'react';
import clsx from 'clsx';

import useMediaQuery from '../../../../useMediaQuery';

import styles from './Icon.module.scss';

interface FirstDiscProps {
  activeDisc: String;
  setActiveDisc: (active: string) => void;
}

const FirstDisc = ({ activeDisc, setActiveDisc }: FirstDiscProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <svg
      width={isMobile ? '20' : '24'}
      height={isMobile ? '20' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.root, { [styles.active]: activeDisc === 'wheels_1' })}
      id="wheels_1"
      onClick={() => setActiveDisc('wheels_1')}
    >
      <g clipPath="url(#clip0_234_98)">
        <path d="M20.4797 3.52031C18.2297 1.27031 15.1781 0.0046875 12 0C9.62812 0 7.30781 0.703125 5.33437 2.02031C3.36093 3.34219 1.82343 5.21719 0.914058 7.40625C0.00468329 9.6 -0.234379 12.0141 0.229683 14.3391C0.693746 16.6687 1.8375 18.8062 3.51562 20.4844C5.19375 22.1625 7.33125 23.3062 9.66093 23.7703C11.9906 24.2344 14.4 23.9953 16.5937 23.0859C18.7875 22.1766 20.6625 20.6391 21.9797 18.6656C23.2969 16.6922 24 14.3719 24 12C23.9953 8.81719 22.7297 5.77031 20.4797 3.52031ZM12 21.4734C6.76875 21.4734 2.52656 17.2313 2.52656 12C2.52656 6.76875 6.76875 2.52656 12 2.52656C17.2312 2.52656 21.4734 6.76875 21.4734 12C21.4734 17.2313 17.2312 21.4734 12 21.4734Z" />
        <path d="M12.0094 11.9953C12.012 11.9953 12.0141 11.9932 12.0141 11.9906C12.0141 11.9881 12.012 11.986 12.0094 11.986C12.0069 11.986 12.0048 11.9881 12.0048 11.9906C12.0048 11.9932 12.0069 11.9953 12.0094 11.9953Z" />
        <path d="M19.9735 6.47345L14.1938 12.1781L11.7985 9.78282L17.5501 4.0547L19.9735 6.47345ZM21.9704 6.48282L17.5548 2.0672L9.81104 9.78282L14.1892 14.1609L21.9704 6.48282Z" />
        <path d="M12.0235 11.9953C12.0261 11.9953 12.0282 11.9932 12.0282 11.9906C12.0282 11.9881 12.0261 11.986 12.0235 11.986C12.0209 11.986 12.0188 11.9881 12.0188 11.9906C12.0188 11.9932 12.0209 11.9953 12.0235 11.9953Z" />
        <path d="M21.5532 13.7203L13.4344 13.6641V10.2797L21.5532 10.2938V13.7203ZM22.9594 15.136V8.89222L12.0282 8.87347V15.0657L22.9594 15.136Z" />
        <path d="M12.0329 12C12.0355 12 12.0376 11.9979 12.0376 11.9953C12.0376 11.9927 12.0355 11.9906 12.0329 11.9906C12.0303 11.9906 12.0282 11.9927 12.0282 11.9953C12.0282 11.9979 12.0303 12 12.0329 12Z" />
        <path d="M17.5454 19.9594L11.8407 14.1797L14.236 11.7844L19.9688 17.5406L17.5454 19.9594ZM17.5407 21.9562L21.9563 17.5406L14.2407 9.79688L9.86255 14.175L17.5407 21.9562Z" />
        <path d="M12.0328 12.0141C12.0354 12.0141 12.0375 12.012 12.0375 12.0094C12.0375 12.0068 12.0354 12.0047 12.0328 12.0047C12.0302 12.0047 12.0281 12.0068 12.0281 12.0094C12.0281 12.012 12.0302 12.0141 12.0328 12.0141Z" />
        <path d="M10.2984 21.5391L10.3547 13.4203H13.7391L13.725 21.5391H10.2984ZM8.88281 22.9453H15.1266L15.1453 12.0141H8.95781L8.88281 22.9453Z" />
        <path d="M12.0235 12.0235C12.0261 12.0235 12.0282 12.0214 12.0282 12.0188C12.0282 12.0162 12.0261 12.0141 12.0235 12.0141C12.0209 12.0141 12.0188 12.0162 12.0188 12.0188C12.0188 12.0214 12.0209 12.0235 12.0235 12.0235Z" />
        <path d="M4.05937 17.5359L9.83906 11.8313L12.2344 14.2266L6.48281 19.9547L4.05937 17.5359ZM2.0625 17.5266L6.47812 21.9422L14.2219 14.2266L9.84375 9.84845L2.0625 17.5266Z" />
        <path d="M12.0141 12.0235C12.0167 12.0235 12.0188 12.0214 12.0188 12.0188C12.0188 12.0162 12.0167 12.0141 12.0141 12.0141C12.0115 12.0141 12.0094 12.0162 12.0094 12.0188C12.0094 12.0214 12.0115 12.0235 12.0141 12.0235Z" />
        <path d="M2.47974 10.2891L10.5985 10.3453V13.7297L2.47974 13.7157V10.2891ZM1.07349 8.87347V15.1172L12.0047 15.136V8.94379L1.07349 8.87347Z" />
        <path d="M12.0047 12.0188C12.0073 12.0188 12.0094 12.0167 12.0094 12.0141C12.0094 12.0115 12.0073 12.0094 12.0047 12.0094C12.0021 12.0094 12 12.0115 12 12.0141C12 12.0167 12.0021 12.0188 12.0047 12.0188Z" />
        <path d="M6.48755 4.05004L12.1922 9.82972L9.79692 12.2203L4.06411 6.46879L6.48755 4.05004ZM6.49224 2.05316L2.0813 6.46879L9.79692 14.2125L14.175 9.83441L6.49224 2.05316Z" />
        <path d="M12 12.0047C12.0026 12.0047 12.0047 12.0026 12.0047 12C12.0047 11.9975 12.0026 11.9954 12 11.9954C11.9975 11.9954 11.9954 11.9975 11.9954 12C11.9954 12.0026 11.9975 12.0047 12 12.0047Z" />
        <path d="M13.7344 2.47034L13.6782 10.5891H10.2938L10.3079 2.47034H13.7344ZM15.1501 1.06409H8.90632L8.88757 11.9953H15.0798L15.1501 1.06409Z" />
        <path d="M12 15.225C13.7811 15.225 15.225 13.7811 15.225 12C15.225 10.2189 13.7811 8.77502 12 8.77502C10.2189 8.77502 8.77502 10.2189 8.77502 12C8.77502 13.7811 10.2189 15.225 12 15.225Z" />
      </g>
      {/*<defs>*/}
      {/*  <clipPath id="clip0_234_98">*/}
      {/*    <rect*/}
      {/*      width="24"*/}
      {/*      height="24"*/}
      {/*      fill="white"*/}
      {/*    />*/}
      {/*  </clipPath>*/}
      {/*</defs>*/}
    </svg>
  );
};

export default FirstDisc;
