import clsx from 'clsx';
import { useContext, useState } from 'react';

import { ActiveButtonContext } from '../../../../App';

import styles from './Icon.module.scss';

interface PlayIconProps {
  isActivePlay: boolean;
  setIsActivePlay: (active: boolean) => void;
  activeId: string;
  setActiveId: (active: string) => void;
}

const PlayIcon = ({ isActivePlay, setIsActivePlay, activeId, setActiveId }: PlayIconProps) => {
  const [isActive, setIsActive] = useState(false);
  const playButton = ['tour_pause', 'tour_resume'];
  const { setIsFootnote } = useContext(ActiveButtonContext);

  const handleChange = (id: string) => {
    setIsActive(!isActive);
    setIsActivePlay(!isActivePlay);
    setActiveId(id);
    setIsFootnote(false);
  };

  return (
    <>
      {playButton.map((item, index) => (
        <button
          key={index}
          id={item}
          onClick={() => handleChange(item)}
          className={clsx(styles.root, {
            [styles.block]: activeId !== item,
            [styles.none]: activeId === item,
          })}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(styles.play, { [styles.pause]: !isActivePlay })}
          >
            <g clipPath="url(#clip0_649_1212)">
              <path d="M2.51341 11.6623C2.2122 11.965 1.75778 12.0564 1.36313 11.8932C0.96822 11.7306 0.710602 11.3451 0.710602 10.9184V1.05553C0.710602 0.628259 0.96822 0.243267 1.36313 0.0803962C1.75804 -0.0827359 2.2122 0.0086181 2.51341 0.311391L7.17559 4.99681C7.72059 5.54441 7.72059 6.42924 7.17559 6.97658L2.51341 11.6623Z" />
              <path d="M9.98434 12C9.26317 12 8.67928 11.4159 8.67928 10.6949V1.30506C8.67928 0.584143 9.26317 0 9.98434 0C10.7053 0 11.2894 0.584143 11.2894 1.30506V10.6949C11.2894 11.4156 10.705 12 9.98434 12Z" />
            </g>
          </svg>
        </button>
      ))}
    </>
  );
};

export default PlayIcon;
