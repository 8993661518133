export const discsColors = [
  { id: 1, color: '#000000' },
  { id: 2, color: '#FAFAFA' },
  { id: 3, color: '#B2B2B2' },
  { id: 4, color: '#0C714C' },
  { id: 5, color: '#4B84C7' },
  { id: 6, color: '#D78033' },
  { id: 7, color: '#B63333' },
  { id: 8, color: '#6D470D' },
];
