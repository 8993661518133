import { useContext } from 'react';

import { ActiveButtonContext } from '../../../App';

import styles from './IconsButtonsBlock.module.scss';
import OpenDoorsIcon from './Icons/OpenDoorsIcon';
import LightIcon from './Icons/LightIcon';
import SeatIcon from './Icons/SeatIcon';

const IconsButtonsBlock = () => {
  const { activeDrive, isHideDuringDrive } = useContext(ActiveButtonContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <OpenDoorsIcon
          isHideDuringDrive={isHideDuringDrive}
          isHide={activeDrive}
        />
        <LightIcon
          isHideDuringDrive={isHideDuringDrive}
          isHide={activeDrive}
        />
        <SeatIcon
          isHideDuringDrive={isHideDuringDrive}
          isHide={activeDrive}
        />
      </div>
    </div>
  );
};

export default IconsButtonsBlock;
