import clsx from 'clsx';
import { useState } from 'react';

import ButtonWrapper from '../../../shared/ButtonWrapper';

import styles from './DiscsOptions.module.scss';
import FirstDisc from './Icons/FirstDisc';
import SecondDisc from './Icons/SecondDisc';
import ThirdDisc from './Icons/ThirdDisc';

interface DiscsOptionsProps {
  activeChangeDisc: String;
}

const DiscsOptions = ({ activeChangeDisc }: DiscsOptionsProps) => {
  const [activeDisc, setActiveDisc] = useState('wheels_2');

  return (
    <ButtonWrapper
      className={clsx(styles.root, {
        [styles.flex]: activeChangeDisc === 'disk',
      })}
    >
      <FirstDisc
        activeDisc={activeDisc}
        setActiveDisc={setActiveDisc}
      />
      <SecondDisc
        activeDisc={activeDisc}
        setActiveDisc={setActiveDisc}
      />
      <ThirdDisc
        activeDisc={activeDisc}
        setActiveDisc={setActiveDisc}
      />
    </ButtonWrapper>
  );
};

export default DiscsOptions;
