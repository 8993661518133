import { useContext, useState } from 'react';

import { ActiveButtonContext } from '../../../App';

import styles from './TourButtonsBlock.module.scss';
import CloseIcon from './Icons/CloseIcon';
import PrevIcon from './Icons/PrevIcon';
import PlayIcon from './Icons/PlayIcon';
import NextIcon from './Icons/NextIcon';

const TourButtonsBlock = () => {
  const [isActivePlay, setIsActivePlay] = useState(true);
  const [activeId, setActiveId] = useState('tour_resume');
  const { activeTour } = useContext(ActiveButtonContext);

  return (
    <div className={activeTour ? styles.root : styles.none}>
      <CloseIcon setIsActivePlay={setIsActivePlay} />
      <div className={styles.wrapper}>
        <PrevIcon
          setIsActivePlay={setIsActivePlay}
          setActiveId={setActiveId}
        />
        <NextIcon
          setIsActivePlay={setIsActivePlay}
          setActiveId={setActiveId}
        />
      </div>
      <PlayIcon
        isActivePlay={isActivePlay}
        setIsActivePlay={setIsActivePlay}
        activeId={activeId}
        setActiveId={setActiveId}
      />
    </div>
  );
};

export default TourButtonsBlock;
